import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import {Entity} from 'src/app/shared/models/entity.model';
import {ActionsListQueryParamValue, ActionsListCommonParamsValue} from './models/actions-list-query-params.model';
import {ActionsEntitiesFilterService} from './services/actions-entities-filters.service';
import {ActionsCommonFiltersService} from './services/actions-common-filters.service';
import {GlobalEntitiesService} from "../../../services/rest-services/global-entities.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-main-actions-dashboard',
  templateUrl: './main-actions-dashboard.component.html',
  styleUrls: ['./main-actions-dashboard.component.scss']
})
export class MainActionsDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() currentEntity: Entity;
  queryParams: ActionsListQueryParamValue = {};
  commonFiltersParams: ActionsListCommonParamsValue;
  selectedEntity: Entity;
  highOrg: Entity;

  constructor(private actionsEntitiesFilterService: ActionsEntitiesFilterService,
              private actionsCommonFiltersService: ActionsCommonFiltersService,
              private globalEntitiesService: GlobalEntitiesService) {
  }


  ngOnInit() {
    this.globalEntitiesService.highLevelOrg$.pipe(take(1)).subscribe(hierarchy => {
      this.highOrg = this.actionsEntitiesFilterService.getOrgEntity(hierarchy);
    });
  }

  /**
   * 1. Assign new query param to the queryParams object
   * 2. create new object in order to activate change detection at child component
   * 3. Assign both commonFiltersParams and selectedEntity param with relevent data
   */
  onQueryParamChanged(params: ActionsListQueryParamValue) {
    for (let [key, value] of Object.entries(params)) {
      this.queryParams[key] = value;
      this.queryParams = {...this.queryParams};
    }
    this.commonFiltersParams = this.actionsCommonFiltersService.getCommonFiltersParams(this.queryParams);
    let entitiesFiltersParams = this.actionsEntitiesFilterService.getEntityFromFilters(this.queryParams);
    entitiesFiltersParams ? this.selectedEntity = entitiesFiltersParams :
      this.selectedEntity = {...this.currentEntity};
  }
}
