import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Entity} from "../../../../../../models/entity.model";
import {FilterData, FiltersBar} from "../../../../../filters-bar/models/filters-bar.model";
import {AnomaliesEntitiesFiltersService} from "./anomalies-entities-filters.service";
import {NgChanges} from "../../../../../../extend-angular-classes/on-changes";
import {EntityType} from "../../../../../../models/entity-type.enum";
import {StoreDashboardService} from "../../../../services/store-dashboard.service";
import {DashboardType} from "../../../../models/dashboards.model";
import {Page} from "../../../../../../models/page.model";
import {AnomalyLeanGridDTO} from "../../anomalies-lean-grid/anomaly-lean-grid.model";

@Component({
  selector: 'app-anomalies-grid-filters',
  templateUrl: './anomalies-grid-filters.component.html',
  styleUrls: ['./anomalies-grid-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnomaliesGridFiltersComponent {
  @Input() currentEntity: Entity;
  @Input() selectedEntity: Entity;
  @Input() leanGridData: Page<AnomalyLeanGridDTO>;
  @Output() entitySelected: EventEmitter<Entity> = new EventEmitter<Entity>();
  anomaliesFilters: FiltersBar<any, any>[] = [];

  constructor(private anomaliesEntitiesFiltersService: AnomaliesEntitiesFiltersService, private cdr: ChangeDetectorRef,
              private storeDashboardService: StoreDashboardService) {
  }

  ngOnChanges(changes: NgChanges<AnomaliesGridFiltersComponent>) {
    if (changes.currentEntity && this.currentEntity) {
      this.anomaliesFilters = [];
      this.anomaliesEntitiesFiltersService.generateEntitiesFilters(this.anomaliesFilters, this.currentEntity, this.selectedEntity, this.leanGridData, this.cdr);
    }
    if (changes.leanGridData) {
      this.resetFiltersToAllOption();
    }
  }

  onFilterItemSelected(event: FilterData<number, EntityType>) {
    let entity: Entity;
    if (!event.isFakeItem) {
      entity = {
        id: event.data,
        name: '',
        type: event.type
      }
    } else {
      entity = {
        id: this.currentEntity.id,
        name: this.currentEntity.name,
        type: this.currentEntity.type
      }
    }
    this.entitySelected.emit(entity);
  }

  get isDeviceDashboard() {
    return this.storeDashboardService.currentDashboard === DashboardType.DEVICES_HEALTH_DASHBOARD;
  }

  private resetFiltersToAllOption() {
    this.anomaliesFilters.forEach(filter => {
      filter.data = filter.data.filter(item => item.isFakeItem);
    })
  }
}
