/// <reference types="@types/google.maps" />

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Logger } from "loglevel";
import { MarkerLocation, VenueLocation, DeviceLocation } from "../../models/map.model";
import { OrgAddress } from "../../models/organizations.model";
import { Venue } from "../../models/venues.model";
import { LoggerService } from "../../services/logger.service";
import { isHealthLocation } from "./map.operators";
import MarkerClusterer from "@google/markerclusterer";
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  @Input() orgAddress: OrgAddress;
  @Input() withSearch: boolean = false;
  @Input() markersLocations: (MarkerLocation | VenueLocation | DeviceLocation)[];
  @Input() mapContainerCss: string;
  @Output() selectedMarkrId: EventEmitter < any > = new EventEmitter();
  @Output() selectedAddress: EventEmitter < any > = new EventEmitter();

  map: google.maps.Map;
  venues: Array < Venue > ;
  locations: (MarkerLocation | VenueLocation | DeviceLocation)[];
  logger: Logger;
  markLabel: google.maps.MarkerLabel;
  markerClusterer: any;
  searchText: string;
  isReady: boolean = false;

  constructor(
    private loggerService: LoggerService,
  ) {
    this.logger = this.loggerService.getLogger("MapComponent");
  }
  @ViewChild('map') mapElement: ElementRef;
  @ViewChild('autocomplete') autocomplete: ElementRef;

  async ngAfterViewInit() {
    google.maps.marker.AdvancedMarkerElement.prototype["getPosition"] = function() { return new google.maps.LatLng(this.position.lat, this.position.lng);}
    google.maps.marker.AdvancedMarkerElement.prototype["getMap"] = function() { return this.map; }
    this.initMap();
  }

  /**
   * In order to draw map when data is received and when data is empty,
   * we need to invoke initMap inside to lifecycles methods: OnChanges and AfterviewInit
   */
  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug("changes", changes);
    this.loadMarkerLocations();
  }

  /**
   * Load locations with current devices array (markerlocations)
   */
  loadMarkerLocations() {
    this.locations = [];

    if (this.markersLocations?.length > 0 && this.markersLocations[0].lat && this.markersLocations[0].lng) {
      this.locations = this.markersLocations;
      this.isReady = true;
      this.initMap();
    }
  }

  /**
   * The main logic method
   * The method set markers and this.map object. If current this.map has search input, call method
   * that will set the search mode
   */
  initMap() {
    if (!this.mapElement) return;
    this.logger.debug("Inside initMap");

    const mapOptions = {
      mapId: 'YOUR_MAP_ID',
      zoom: this.locations.length > 0 ? 17 : 1,
      center: this.locations && this.locations.length > 0 ? {
        lat: this.locations[0].lat,
        lng: this.locations[0].lng
      } : {
        lat: 41.904365,
        lng: 12.479000
      },
      mapTypeControl: false,
      streetViewControl: false,
      panControl: true,
      zoomControl: true
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.setLocationMarker();
  }


  /**
   * Set locations marker when devices array was receivced
   */
  setLocationMarker() {
    if (this.locations?.length > 0) {
      const markerIds = [],

      markers = this.locations.map((location) => {
        const markerId = `${location.lat}/${location.lng}`;
        if (!markerIds[markerId]) {
          markerIds[markerId] = true;

          const hasHealth = !this.withSearch && isHealthLocation(location);

          let color;;
          if (hasHealth) {
            const health = location['health'];
            switch (health) {
              case health <= 59:
                color = 'red';
                break;
              case health >= 60 && health <= 90:
                color = 'yellow';
                break;
              case health >= 90:
                color = 'green';
                break;
              default:
                break;
            }
          }

          const pinGlyph = new google.maps.marker.PinElement();
          if (hasHealth) {
            pinGlyph.glyphColor = color;
          }

          let marker = new google.maps.marker.AdvancedMarkerElement({
            map: this.map,
            position: {lat: location.lat, lng: location.lng},
            title: location.name,
            content: pinGlyph.element
          });

          marker.addListener('click', event => {
            this.selectedMarkrId.emit(location);
          });

          return marker;
        }
      }).filter(marker => marker);

      /**
       * The next section sets the zoom and center of the map, in relation to the markers positions
       * If there is only one marker, the zoom and center will be set manually
       */
      if (this.locations.length > 1) {
        let bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].position);
        }
        this.map.fitBounds(bounds);
      }

      this.markerClusterer = new MarkerClusterer(this.map, markers);

      /**
       * @param cluster_styles contains the clustrer style data
       */
      let cluster_styles = [{
        width: 50,
        height: 50,
        url: this.getGoogleClusterInlineSvg("#4DB2FF"),
        textColor: '#000000',
        textSize: 12,
        cursor: 'pointer',
      }];
      /**
       * markerCLustrer must get it custome styles only after @method findMostIllMarkerInCluster was invoked, so that
       * @param markerClusterColor will already be initilized
       */
      this.markerClusterer.styles_ = cluster_styles;
    }
  }

  /**
   * @method getGoogleClusterInlineSvg Create the clustrerer svg file.
   * @param color is the color that will be filling the svg shapes
   */
  private getGoogleClusterInlineSvg(color) {
    let encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');
    return ('data:image/svg+xml;base64,' + encoded);
  };

}
