<app-portlet [class]="'action-portlet-container'">
    <app-portlet-body class="portlet-body-entity-list no-header-widget-portlet">
        <div class="row main-actions-container-row">
            <div class="col main-actions-container-col">
                <div class="row main-actions-filters-container">
                    <div class="col full-size">
                        <app-actions-filters [currentEntity]="currentEntity" [highOrg]="highOrg"
                            (queryParamChanged)="onQueryParamChanged($event)"></app-actions-filters>
                    </div>
                </div>
                <div class="row main-actions-list-container">
                    <div class="col">
                        <app-actions-list [currentEntity]="selectedEntity" [commonFiltersParams]="commonFiltersParams">
                        </app-actions-list>
                    </div>
                </div>
            </div>
        </div>
    </app-portlet-body>
</app-portlet>
