import {FilterData, FiltersBar} from 'src/app/shared/components/filters-bar/models/filters-bar.model';
import {Entity} from 'src/app/shared/models/entity.model';
import {ActionsListQueryParamsEnum, ActionsListQueryParamValue} from '../../models/actions-list-query-params.model';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {ActionsCommonFiltersService} from '../../services/actions-common-filters.service';
import {ActionsEntitiesFilterService} from '../../services/actions-entities-filters.service';
import {
  InsightsAnomaliesTimeGeneratorService
} from '../../../../dynamic-dashboard/components/insights-anomalies/services/insights-anomalies-time-generator.service';
import {NgChanges} from "../../../../../extend-angular-classes/on-changes";
import {TimePeriodOption, TimePeriodPayload} from "../../../../../models/time.model";
import {ActionsFiltersStateStore} from "../../services/actions-filters-state.service";
import {ActivatedRoute} from "@angular/router";
import {DEFAULT_TIME_PERIOD_OPTIONS} from "../../../../time-range-picker/time-range-picker.model";
import {LegacySeverity} from "../../../../../models/severity.model";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-actions-filters',
  templateUrl: './actions-filters.component.html',
  styleUrls: ['./actions-filters.component.scss']
})
export class ActionsFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentEntity: Entity;
  @Input()   highOrg: Entity;
  @Output() queryParamChanged: EventEmitter<ActionsListQueryParamValue> = new EventEmitter();
  queryParams: any;
  timePeriodOptions: TimePeriodOption[] = DEFAULT_TIME_PERIOD_OPTIONS;
  currentTimePeriodSelectionIndex: number = null;
  maxDateLimit: Date = new Date();
  actionsFilters: FiltersBar<any, any>[] = [];
  initialized: boolean = false;

  constructor(
    private actionsCommonFiltersService: ActionsCommonFiltersService,
    private actionsEntitiesFilterService: ActionsEntitiesFilterService,
    private actionsFiltersStateStore: ActionsFiltersStateStore,
    private datesGenerator: InsightsAnomaliesTimeGeneratorService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.queryParams = this.activatedRoute.snapshot?.queryParams;

    //Disable filter Persistance
    this.actionsFiltersStateStore.clearState(this.currentEntity?.type);

    if (this.queryParams) {
      if (this.queryParams['clearFilters']) {
        this.actionsFiltersStateStore.clearState(this.currentEntity?.type);
      }
      if (this.queryParams['severity']) {
        this.actionsFiltersStateStore.addFilterValue(this.currentEntity?.type, ActionsListQueryParamsEnum.Severities,
          [LegacySeverity.toString(this.queryParams['severity'])]);
        this.actionsFiltersStateStore.updateState();
      }
      if (this.queryParams['timeBack'] && this.queryParams['timeUnit']) {
        this.currentTimePeriodSelectionIndex = this.timePeriodOptions.findIndex(option =>
            option.interval == this.queryParams['timeBack'] && option.timeUnit == this.queryParams['timeUnit'])
          ?? 0;
      }
      else {
        this.currentTimePeriodSelectionIndex = 0;
      }
      if (this.queryParams['startDate']) {
        this.queryParamChanged.emit({[ActionsListQueryParamsEnum.StartDate]: this.queryParams['startDate'], [ActionsListQueryParamsEnum.EndDate]: new Date().toISOString()});
      }
      else {
        this.queryParamChanged.emit({[ActionsListQueryParamsEnum.StartDate]: new Date().toISOString(), [ActionsListQueryParamsEnum.EndDate]: new Date().toISOString()})
      }
    }
    else {
      this.currentTimePeriodSelectionIndex = 0;
    }

    this.initialized = true;
  }

  ngOnChanges(changes: NgChanges<ActionsFiltersComponent>) {
    if (this.highOrg) {
      this.initiateFilters();
    }
  }

  ngOnDestroy() {
    if (this.queryParams && this.queryParams['severity']) {
      this.actionsFiltersStateStore.clearState(this.currentEntity?.type);
    }
    else {
      this.actionsFiltersStateStore.updateState();
    }
  }

  initiateFilters() {
    this.actionsCommonFiltersService.loadCommonFilters(this.currentEntity.type).subscribe(filters => {
      if (this.currentEntity && (this.currentEntity.type == EntityType.ORGANIZATION || this.currentEntity.type == EntityType.TENANT)) {
        this.actionsEntitiesFilterService.generateEntitiesFilters(this.actionsFilters, this.cdr, this.currentEntity, this.highOrg);
      }
      this.actionsFilters = this.actionsFilters.concat(filters);
      this.cdr.markForCheck();
    });
  }

  onFilterItemSelected(item: FilterData<any, any>) {
    this.actionsEntitiesFilterService.refreshEntitiesFilters(this.actionsFilters, item, this.cdr);
    const filter = this.actionsFilters.find(bar => bar.name === item.filterName);
    if (filter !== undefined) {
      this.queryParamChanged.emit(this.actionsEntitiesFilterService.getChangedParams(filter, item));
    }
    this.actionsFiltersStateStore.addFilterState(this.currentEntity.type, item);
  }

  onTimePeriodSelection(payload: TimePeriodPayload) {
    this.queryParamChanged.emit({
      [ActionsListQueryParamsEnum.StartDate]: payload?.dates?.start?.toISOString(),
      [ActionsListQueryParamsEnum.EndDate]: payload?.dates?.end?.toISOString()
    });
  }
}
