import { Injectable, ChangeDetectorRef } from '@angular/core';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { take } from 'rxjs/operators';
import { FiltersBar, FilterData } from 'src/app/shared/components/filters-bar/models/filters-bar.model';
import { forkJoin } from 'rxjs';
import { Entity } from 'src/app/shared/models/entity.model';
import { FormFieldsType } from 'src/app/shared/modals/models/form-actions.model';
import { GlobalEntitiesService } from 'src/app/shared/services/rest-services/global-entities.service';
import { OrganizationsService } from 'src/app/shared/services/rest-services/organizations.service';
import { VenuesService } from 'src/app/shared/services/rest-services/venues.service';
import {
  ActionsListQueryParamsEnum,
  QueryParamValue,
  ActionsListQueryParamValue,
  ActionsListEntitiesQueryParamsEnum
} from '../models/actions-list-query-params.model';
import { IBaseFilterBarService } from "../../../filters-bar/models/IBase-filter-bar-service";
import { VenueSummary } from 'src/app/shared/models/venues.model';
import { TenantSummaryDTO } from 'src/app/shared/models/tenants.model';
import { OrganizationDetails } from 'src/app/shared/models/organizations.model';
import { BaseFilterBarService } from '../../../filters-bar/models/base-filter-bar-service.model';
import {ActionsFiltersStateStore} from "./actions-filters-state.service";

@Injectable({
  providedIn: 'root'
})
export class ActionsEntitiesFilterService extends BaseFilterBarService implements IBaseFilterBarService {

  constructor(
    protected globalEntitiesService: GlobalEntitiesService,
    protected organizationService: OrganizationsService,
    protected venuesService: VenuesService,
    private actionsFiltersStateStore: ActionsFiltersStateStore
  ) {
    super(globalEntitiesService, organizationService, venuesService);
  }

  generateEntitiesFilters(actionsFilters: FiltersBar<any, any>[], cdr: ChangeDetectorRef, currentEntity: Entity, entityForSelection: Entity) {
    if (currentEntity) {
      switch (currentEntity.type) {
        case EntityType.ORGANIZATION: {
          actionsFilters.push(
            new FiltersBar(ActionsListQueryParamsEnum.Organization, FormFieldsType.SELECT, [], 'All Organizations'),
            new FiltersBar(ActionsListQueryParamsEnum.Tenant, FormFieldsType.SELECT, [], 'All Tenants'),
            new FiltersBar(ActionsListQueryParamsEnum.Venue, FormFieldsType.SELECT, [], 'All Venues'),
          );
          actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Venue).isDisableSelection = true;
          this.getOrgsLevelObservables(currentEntity).pipe(take(1)).subscribe(entitiesData => {
            this.initiateFiltersWithData(actionsFilters, entitiesData, currentEntity, entityForSelection);
            actionsFilters.forEach(filter => filter.addSelectAllOption());
            cdr.detectChanges();
          });
          break;
        }
        case EntityType.TENANT: {
          actionsFilters.push(
            new FiltersBar(ActionsListQueryParamsEnum.Venue, FormFieldsType.SELECT, [], 'All Venues', true),
          );
          this.getTenantsLevelObservables(currentEntity).pipe(take(1)).subscribe(entitiesData => {
            this.initiateFiltersWithData(actionsFilters, entitiesData, currentEntity, entityForSelection);
            actionsFilters.forEach(filter => filter.addSelectAllOption());
            cdr.detectChanges();
          });
        }
          break;
        default:
          break;
      }
    }
  }

  /**
   * Fill the filter with the list for each entity
   */
  initiateFiltersWithData(
    actionsFilters: FiltersBar<any, any>[],
    entitiesData: (
      { Organization: OrganizationDetails[]; Tenant: TenantSummaryDTO[]; } | { venues: VenueSummary[] }) | ({ Organization: OrganizationDetails[]; Tenant: TenantSummaryDTO[]; Venue: VenueSummary[] } | { Venue: VenueSummary[] }),
    currentEntity: Entity,
    highOrg: Entity
  ) {
    Object.keys(entitiesData).forEach(key => {
      if (entitiesData[key] && entitiesData[key].length > 0) {
        const filterName: ActionsListQueryParamsEnum = this.getFilterNameByEntityData(entitiesData[key][0]);
        const entityType: EntityType = this.getEntityTypeByFilterType(filterName);
        const currentFilter = actionsFilters.find(filter => filter.name == filterName);
        entitiesData[key].forEach((entity, index) => {
          currentFilter.data.push({
            id: index + 1,
            name: entity.name,
            type: entityType,
            data: entity.id,
            filterName,
            isSelected: this.actionsFiltersStateStore.stateEqualsValue(currentEntity.type, filterName, entity.id)
          });
        });
      }
    });
  }

  /**
   * Return entity type by entity filter
   */
  getEntityTypeByFilterType(filterName: ActionsListQueryParamsEnum): EntityType {
    switch (filterName) {
      case ActionsListQueryParamsEnum.Organization:
        return EntityType.ORGANIZATION;
      case ActionsListQueryParamsEnum.Tenant:
        return EntityType.TENANT;
      case ActionsListQueryParamsEnum.Venue:
        return EntityType.VENUE;
      default:
        break;
    }
  }

  /**
   * Check if one of the filters lists are equal to one of the current entities, in order to mark them as selected
   */
  isMarkEntityAsSelected(
    entity: OrganizationDetails | TenantSummaryDTO | VenueSummary,
    entityType: EntityType,
    highOrg: Entity,
    currentEntity: Entity): boolean {
    return entityType == highOrg.type && entity.id == highOrg.id || entityType == currentEntity.type && entity.id == currentEntity.id;
  }

  /**
   * Return filter name by the current entity
   */
  getFilterNameByEntityData(entity: OrganizationDetails | TenantSummaryDTO | VenueSummary): ActionsListQueryParamsEnum {
    switch (true) {
      case entity.hasOwnProperty('tenants'):
        return ActionsListQueryParamsEnum.Organization;
      case entity.hasOwnProperty('numOfVenues'):
        return ActionsListQueryParamsEnum.Tenant;
      default:
        break;
    }
    return ActionsListQueryParamsEnum.Venue;
  }

  /**
   * Return filter name by the current entity
   */
  getFilterNameByEntity(entity: Entity): ActionsListQueryParamsEnum {
    switch (true) {
      case entity.type === EntityType.ORGANIZATION:
        return ActionsListQueryParamsEnum.Organization;
      case entity.type === EntityType.TENANT:
        return ActionsListQueryParamsEnum.Tenant;
      default:
        break;
    }
    return ActionsListQueryParamsEnum.Venue;
  }

  getTenantsLevelObservables(currentEntity: Entity) {
    const actionFiltersObservables$ = forkJoin({
      [EntityType.VENUE]: this.venuesService.fetchVenuesSummaryByTenantId(currentEntity.id),
    });
    return actionFiltersObservables$;
  }

  getOrgsLevelObservables(currentEntity: Entity) {
    const actionFiltersObservables$ = forkJoin({
      [EntityType.ORGANIZATION]: this.organizationService.getOrganizationsOnly(),
      [EntityType.TENANT]: this.organizationService.fetchTenantsById(currentEntity.id),
    });
    return actionFiltersObservables$;
  }

  /**
   * Refresh all entities filter data based on selected entity
   */
  refreshEntitiesFilters(actionsFilters: FiltersBar<any, any>[], item: FilterData<any, any>, cdr: ChangeDetectorRef) {
    if (item.type !== EntityType.ORGANIZATION && item.type !== EntityType.TENANT) {
      return;
    }
    if (item.filterName == ActionsListQueryParamsEnum.Tenant) {
      const venueFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Venue);
      if (venueFilter) {
        venueFilter.data = [];
        if (item.data) {
          venueFilter.isDisableSelection = false;
          this.venuesService.fetchVenuesSummaryByTenantId(item.data).pipe(take(1)).subscribe(venues => {
            this.enterDataIntoFilter(venues, venueFilter, EntityType.VENUE, cdr);
          });
        } else if (item.isFakeItem) {
          venueFilter.addSelectAllOption();
          venueFilter.isDisableSelection = true;
        }
      }
    }
    if (item.filterName == ActionsListQueryParamsEnum.Organization) {
      const tenantFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Tenant);
      const venueFilter = actionsFilters.find(filter => filter.name == ActionsListQueryParamsEnum.Venue);
      if (venueFilter) {
        venueFilter.data = [];
        venueFilter.isDisableSelection = true;
        venueFilter.addSelectAllOption();
      }
      if (tenantFilter) {
        tenantFilter.data = [];
        if (item.data) {
          this.organizationService.fetchTenantsById(item.data).pipe(take(1)).subscribe(tenants => {
            this.enterDataIntoFilter(tenants, tenantFilter, EntityType.TENANT, cdr);
          });
        } else if (item.isFakeItem) {
          tenantFilter.addSelectAllOption();
        }
      }
    }
  }

  /**
   * Get an array of entities with filter and current entity type and assign the filter.data array with data
   */
  enterDataIntoFilter(entities: VenueSummary[] | TenantSummaryDTO[], filter: FiltersBar<any, any>, entityType: EntityType, cdr: ChangeDetectorRef) {
    entities.forEach((entity, index) => {
      filter.data.push({
        id: index + 1,
        name: entity.name,
        type: entityType,
        data: entity.id,
        filterName: filter.name,
      });
    });
    filter.addSelectAllOption();
    cdr.detectChanges();
  }

  /**
   * Return true if the current filter contain Organizations or tenants data
   */
  isHighEntitiesFilter(filterName: string) {
    return filterName == ActionsListQueryParamsEnum.Organization ||
      filterName == ActionsListQueryParamsEnum.Tenant;
  }

  /**
   * Check if there is need to add properties to the selected item
   * i.e., in case of entity selection, check if there is need to empty other entities
   */
  getChangedParams(filter: FiltersBar<any, any>, item: FilterData<any, any>): QueryParamValue {
    if (!this.isHighEntitiesFilter(item.filterName)) {
      return { [item.filterName]: item.data };
    } else {
      if (item.filterName == ActionsListQueryParamsEnum.Organization) {
        return {
          [item.filterName]: item.data ? item.data : '',
          [ActionsListQueryParamsEnum.Tenant]: '',
          [ActionsListQueryParamsEnum.Venue]: '',
        };
      }
      if (item.filterName == ActionsListQueryParamsEnum.Tenant) {
        return {
          [item.filterName]: item.data ? item.data : '',
          [ActionsListQueryParamsEnum.Venue]: '',
        };
      }
    }
  }


  getEntityFromFilters(params: ActionsListQueryParamValue): Entity {
    let entity: Entity;
    Object.keys(params).forEach(key => {
      if (params[key] && Object.values(ActionsListEntitiesQueryParamsEnum).includes(key as ActionsListEntitiesQueryParamsEnum)) {
        const currentEntity: Entity = {
          id: params[key],
          name: '',
          type: this.getEntityTypeByFilterType(key as ActionsListQueryParamsEnum)
        };
        if (!entity || entity && EntityType.isEntityTypeHigher(currentEntity.type, entity.type)) {
          entity = currentEntity;
        }
      }
    });
    return entity;
  }
}
