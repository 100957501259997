import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, map, startWith} from 'rxjs/operators';
import { MatCheckboxChange} from '@angular/material/checkbox';
import {NgChanges} from "../../extend-angular-classes/on-changes";
import {deepEqual} from "../../operators/object-operators/object-comparison";
import {CheckboxField, CheckboxFieldData, CheckBoxListItem, IndexCheckBoxListItem} from "./checkbox-list.model";


@Component({
  selector: 'app-checkbox-search-list',
  templateUrl: './checkbox-search-list.component.html',
  styleUrls: ['./checkbox-search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxSearchListComponent {
  /**
   * Wrapping the original array with checkbox list array
   * The idea is to generate id for each cell in the array, without "counting"
   * that the data will have id field.
   * The emission will emit only the original data
   */
  @Input() options: CheckBoxListItem<any>[];
  _options: IndexCheckBoxListItem<any>[] = [];
  @Input() optionsListControl: UntypedFormControl = new UntypedFormControl();
  @Input() editMode$: Observable<boolean>;
  @Input() checkboxCss: string;
  @Input() searchField: string;
  @Input() identifierField: string;
  @Input() allowSelectAll: boolean = true;
  @Input() allowMultiSelection: boolean = true;
  @Input() emitSingleSelection: boolean = true;
  @Input() cssText: string;
  @Input() cssList: string;
  @Input() selectionLimit: number;
  @Input() isServerSide: boolean = false;

  /**
   * Allow parent component to set the the displayed fields and fields labels
   */
  @Input() displayedFields: CheckboxField[];
  @Output() searched = new EventEmitter<string>();
  @Output() emitSelectedOptions: EventEmitter<any[]> = new EventEmitter();
  @Output() emitRemovedOptions: EventEmitter<any> = new EventEmitter();
  /**
   * The current status of the selected options array (The source of truth)
   */
  selectedOptions: IndexCheckBoxListItem<any>[] = [];
  /**
   * Observable with the filtered list (filtered by the search input)
   */
  filteredOptionsSubject: Subject<IndexCheckBoxListItem<any>[]> = new Subject();
  filteredOptionsAsObservable$: Observable<IndexCheckBoxListItem<any>[]> = this.filteredOptionsSubject.asObservable();
  /**
   * The filtered list (filtered by the search input)
   * Same as  filteredOptionsAsObservable$ - only synchronously
   */
  filteredOptions: IndexCheckBoxListItem<any>[];
  /**
   * Two ways binding property for the checkAll checkbox element
   */
  checkAll: boolean = false;

  @Input() listCheckAllText: string = "Select All";
  formControlSubscription: Subscription;
  subscription: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.subscribeToFormControl();
  }

  ngOnChanges(changes: NgChanges<CheckboxSearchListComponent>) {
    if (this.optionsListControl && changes.options && !deepEqual(changes.options.currentValue, changes.options.previousValue)) {
      this.buildOptions();
      this.filteredOptionsSubject.next(this._options);
    }

    if (changes.isServerSide) {
      this.subscribeToFormControl();
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.forEach(subsc => subsc.unsubscribe());
  }

  subscribeToFormControl() {
    if (!this.isServerSide) {
      this.subscribeToClientSideFormControl();
    }
    else {
      this.subscribeToServerSideFormControl();
    }
  }

  /**
   * Subscribe to the input Form control changes
   * For each change - If there is text - It calls _filter method. If not, It slice it
   */
  private subscribeToClientSideFormControl() {
    this.unsubscribeFromFormControl();
    this.formControlSubscription = this.optionsListControl.valueChanges
      .pipe(
        startWith(''),
        map(text => (text as string)?.length > 0 ? this._filter(text) : this._options.slice())
      ).subscribe(options => {
        this.buildOptions();
        this.filteredOptionsSubject.next(options);
      });
  }

  private subscribeToServerSideFormControl() {
    this.unsubscribeFromFormControl();
    this.formControlSubscription = this.optionsListControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith('')
      ).subscribe(text => this.searched.emit(text));
  }

  private unsubscribeFromFormControl() {
    if (this.formControlSubscription && !this.formControlSubscription.closed) {
      this.formControlSubscription.unsubscribe();
    }
  }

  private buildOptions() {
    const options: CheckBoxListItem<any>[] = this.options;
    this._options = [];
    if (options && options.length > 0) {
      options.forEach((option, index) => {
        const identifier = this.generateIdentifier(option, index)
        const newItem = new IndexCheckBoxListItem<any>(`${identifier}`, option.data, option.checkboxClass);
        this._options.push(newItem);
      })
    }
  }

  /**
   * The method return filtered the original data array (i.e., The options input property of this component)
   * @param text The current input value
   */
  private _filter(text: string): any[] {
    const filterValue = text.toLowerCase();
    return this._options.filter(option => {
      if (option.data[this.searchField] && typeof option.data[this.searchField] == 'string') {
        return option.data[this.searchField].toLowerCase().includes(filterValue);
      }
    });
  }

  /**
   * Handle checkbox changes, except the one of the check all options
   * @param event The current checked event
   * @param option
   */
  onCheckboxChange(option: any, event?: MatCheckboxChange) {
    if (event && event.checked || !this.isOptionChecked(option)) {
      if (this.allowMultiSelection) {
        this.addSelectedElement(option);
      } else {
        this.replaceCurrentSelection(option);
      }
    } else
      this.removeUnselectedElement(option);
  }

  /**
   * Handle check all checked event
   * If true: Call addSelectedElement and assign to it all filtered options
   * If false: Call removeUnselectedElement for the opposite purpose
   * @param event The current checked event
   */
  onCheckAllChange(event: MatCheckboxChange) {
    if (event.checked)
      this.filteredOptions.forEach(option => this.addSelectedElement(option));
    if (!event.checked)
      this.filteredOptions.forEach(option => this.removeUnselectedElement(option));
  }

  /**
   * Add selected element to the selectedOptions array
   * The method also checks if all the current filtered options list is checked -
   * and assign it check result to the checkAll property
   * @param option The current selected checkbox value
   */
  private addSelectedElement(option: IndexCheckBoxListItem<any>): void {
    const isAlreadySelected = this.selectedOptions.find(selectedOption => selectedOption.checkboxID == option.checkboxID)
    if (!isAlreadySelected) {
      this.selectedOptions.push(option);
      if (this.emitSingleSelection) {
        this.emitSelectedOptions.emit([option.data]);
      } else {
        this.emitSelectedOptions.emit(this.selectedOptions.map(option => option.data));
      }
    }
    this.checkAll = this.isAllFilteredAreChecked();
  }

  replaceCurrentSelection(option: any) {
    const isAlreadySelected = this.selectedOptions.find(selectedOption => selectedOption.checkboxID == option.checkboxID)
    if (!isAlreadySelected) {
      this.selectedOptions = [];
      this.selectedOptions.push(option);
      this.emitSelectedOptions.emit(this.selectedOptions.map(option => option.data));
    }
  }

  /**
   * The method removes unchecked element from the selectedOptions array.
   * After it, it set the checkAll property to false
   * @param option The current selected checkbox value
   */
  removeUnselectedElement(option: IndexCheckBoxListItem<any>): void {
    const index = this.selectedOptions.findIndex(selectedOption => selectedOption.checkboxID == option.checkboxID);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      if (this.emitSingleSelection) {
        this.emitRemovedOptions.emit(option.data);
      } else {
        this.emitSelectedOptions.emit(this.selectedOptions.map(option => option.data));
      }
      this.checkAll = false;
    }
  }

  /**
   * Check if all the elements in the current filtered array are checked
   */
  private isAllFilteredAreChecked(): boolean {
    let isAllFilteredChecked: boolean = true;
    if (this.filteredOptions.length > 0 && this.selectedOptions.length > 0) {
      this.filteredOptions.forEach(filtered => {
        if (!this.selectedOptions.find(option => option.checkboxID == filtered.checkboxID))
          isAllFilteredChecked = false;
      })
      return isAllFilteredChecked;
    }
  }

  /**
   * Return boolean value for each element.
   * The method checks if the current element is in the selectedOptions array and return the answer
   */
  isOptionChecked(option: IndexCheckBoxListItem<any>): boolean {
    const isOptionSelected = this.selectedOptions.find(selectedOption => selectedOption.checkboxID == option.checkboxID);
    return isOptionSelected != undefined;
  }

  getFieldContent(optionData: any, data: CheckboxFieldData) {
    let fieldContent: string = "";
    if (optionData[data.fieldName])
      fieldContent += optionData[data.fieldName];
    if (data.appendContent)
      fieldContent += " " + data.appendContent;
    if (!optionData[data.fieldName])
      if (data.errorMessage) fieldContent = data.errorMessage;
    return fieldContent;
  }

  get checkedOptions() {
    return this.selectedOptions.filter(selectedOption => this._options.find(option => option.checkboxID === selectedOption.checkboxID) !== undefined).length;
  }

  /**
   * Find identifier field and extract identifier data
   * @param option
   * @param index
   * @private
   */
  private generateIdentifier(option: CheckBoxListItem<any>, index: number) {
    if (this.identifierField) {
      if (option.data.hasOwnProperty('originalData')) {
        return option.data.originalData[this.identifierField];
      }
      return option.data[this.identifierField];
    }
    return `${index}.${option.data[this.searchField]}`;
  }
}
