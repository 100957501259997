import {AuthMethod, DashboardEnvironment, EnvironmentModel, EnvironmentType} from './environment.model';

export const environment : EnvironmentModel= {
  environmentType: EnvironmentType.PRODUCTION,
  dashboardEnvironment: DashboardEnvironment.Remote,
  disabledAuth: false,
  disableFirebase: false,
  authMethod: AuthMethod.AUTH_0,
  domain: 'netop.auth0.com',
  client_id: 'rpYG0WUV9tn96qT1majxCihDKNDnjKuL',
  redirect_uri: 'https://production.opscenter.cloud/auth/auth0callback', // `${window.location.origin}/auth/auth0callback`
  production: true,
  disableInMemory: true,
  partialInMemory: false,
  disableSLAs: false,
  apiBaseUrl: 'https://netopcld.net/1.0',
  searchUrl: 'https://netopcld.net/1.0/search',
  versionCheckURL: "version.json",
  fakeData: true,
  firebase: {
    apiKey: "AIzaSyBgs65zrgXMQpBT2Ntl5I-1m1QiljRdE7A",
    authDomain: "netop-engineering.firebaseapp.com",
    databaseURL: "https://netop-engineering.firebaseio.com",
    projectId: "netop-engineering",
    region: "us-central1",
    storageBucket: "netop-engineering.appspot.com",
    messagingSenderId: "1085974882677",
    appId: "1:1085974882677:web:e9ec502bacee0ad5",
    measurementId: "G-XL0CPSR79X",
    localInvocation: false
  },
  enableHotjar: true,
  hotjar: {
    id: '3355957'
  },
  useServerless: true
};
